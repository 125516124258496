<template>
  <div
    class="task-item"
    :style="{
      backgroundColor:
        item.status === 0 ? `#${item.category.color}` : '#B3B3B3',
    }"
  >
    <router-link
      :to="`/deal/${this.item.id}`"
      class="task-item-top"
      tabindex="1"
    >
      <div class="left">
        <p class="task-item-top__name">{{ item.title }}</p>
        <p class="task-item-top__who" v-if="item.user" tabindex="2">
          {{ $t("taskItem.with") }} <span>{{ fullname }}</span>
        </p>
      </div>
      <div class="right" v-if="item.members.length" tabindex="2">
        <div v-for="(elem, idx) in item.members" :key="idx">
          <img :src="elem" alt="" srcset="" />
        </div>
        <div class="count" v-if="item.active_users > 3">
          +{{ item.active_users }}
        </div>
      </div>
    </router-link>
    <div class="task-item-bottom" v-if="item.joined == 1">
      <div class="left" @click="goTo()">
        <span class="big">{{ item.progress_days }}</span>
        <span class="small">
          {{
            $t("taskItem.days", {
              day: item.all_days,
            })
          }}
        </span>
      </div>
      <div class="right">
        <van-button
          plain
          type="primary"
          v-if="item.completed == 0"
          @click="goToAddReport()"
        >
          {{ $t("taskItem.addReport") }}
        </van-button>
        <van-button plain type="primary" class="done" v-else>
          {{ $t("taskItem.done") }}
        </van-button>
      </div>
    </div>
    <div class="task-item-bottom noJoined" v-else>
      <div class="left">
        <span class="big">{{ item.progress_days }}</span>
        <span class="small">
          {{
            $t("taskItem.days", {
              day: item.all_days,
            })
          }}
        </span>
      </div>
      <ul v-if="item.joined === 0" class="tags">
        <li v-if="item.start_date" class="tag">🧨 с {{ item.start_date }}</li>
        <li class="tag" v-if="params.type !== 'finished'">⏳ {{ frequency }}</li>
        <!-- <li class="tag">💸 10$</li> -->
      </ul>

      <div class="right" v-if="params.type !== 'finished'">
        <div class="date-day">{{ item.duration }}</div>
        <div class="date-name">дней</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      default: () => {},
      type: Object,
    },
    params: {
      default: () => {},
      type: Object,
    },
  },
  computed: {
    frequency() {
      if (this.item.start_type === 0) {
        return "Каждый день";
      } else if (this.item.type === 1) {
        return `${this.item.freq} раз`;
      }
    },
    fullname() {
      if (this.item.user) {
        return `${this.item.user.first_name} ${this.item.user.last_name}`;
      }
    },
  },
  methods: {
    goTo() {
      this.$router.push(`/deal/${this.item.id}`);
    },
    goToAddReport() {
      this.$router.push(`/deal/${this.item.id}?component=MakeReport`);
    },
  },
};
</script>

<style lang="scss" scoped>
.task-item {
  padding: 24px;
  margin-bottom: 8px;
  background: #9784b5;
  border-radius: 32px;
  cursor: pointer;
  max-height: min-content;
  min-height: 184px;
  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 46px;
    &__name {
      color: rgba(14, 16, 18, 0.64);
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 8px;
    }
    &__who {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: rgba(14, 16, 18, 0.4);
      span {
        color: rgba(14, 16, 18, 0.64);
      }
    }
    .left {
      max-width: 202px;
    }
    .right {
      display: flex;
      div {
        min-width: 28px;
        min-height: 28px;

        max-width: 28px;
        max-height: 28px;
        background: #3f3a4d;
        overflow: hidden;
        border-radius: 100%;
        &.count {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-weight: 500;
          font-size: 12px;
        }
        img {
          width: 100%;
          height: 100%;
        }
        &:not(:first-child) {
          margin-left: -15px;
        }
      }
    }
  }
  &-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.noJoined {
      .left {
        margin-right: 15px;
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: auto;
        font-family: "Prata";

        .date-day {
          margin-bottom: 6px;
          font-size: 32px;
        }

        .date-name {
          font-size: 12px;
        }
      }
    }
    .left {
      font-family: "Prata";
      color: rgba(14, 16, 18, 0.64);
      font-weight: 400;
      .big {
        text-transform: uppercase;

        margin-right: 8px;

        font-size: 32px;
        line-height: 32px;
      }
      .small {
        font-size: 12px;
        line-height: 20px;
      }
    }
    .right {
    }
  }
}
</style>