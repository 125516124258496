<template>
  <div class="container">
    <user-data :newUser="newUser" />

    <div class="date-info">
      <div class="date-info__date">{{ getDataOrFilter }}</div>
      <div class="date-info__btn">
        <van-popover
          v-model="showPopover"
          trigger="click"
          placement="bottom-end"
          :actions="actions"
          :close-on-click-outside="true"
          @open="onOpen"
        >
          <template #reference>
            <img src="@/assets/images/candle.svg" alt="" srcset="" />
            <div
              class="choosenFilter"
              v-if="filterBy && filterBy != 'all'"
            ></div>
          </template>
          <div class="popup-filter-content">
            <van-radio-group v-model="filterBy">
              <van-radio
                icon-size="24px"
                v-for="(item, key) in actions"
                @click="showPopover = false"
                :key="key"
                :name="item.type"
                >{{ item.text }}</van-radio
              >
            </van-radio-group>
          </div>
        </van-popover>
      </div>
    </div>

    <div class="progress-bar" v-if="!newUser">
      <div
        :class="[
          'progress-bar__item',
          item <= progress.completed_deals ? 'done' : '',
        ]"
        v-for="(item, key) in this.progress.all_deals"
        :key="key"
      ></div>
    </div>

    <Button
      @click.native="goToReport()"
      class="general-report-link"
      :button="{
        title: 'Общая лента отчетов',
        color: '#1D1B20',
        textAlign: 'center',
        borderRadius: '100px',
        backgroundColor: '#E5E7E1',
        height: '40px',
        fontSize: '14px',
        paddingX: '17px',
        borderColor: '#E5E7E1',
      }"
    />

    <template v-if="taskList.length">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text=""
        :loading-text="$t('loading')"
        @load="onLoad"
      >
        <TaskItem
          v-for="(item, key) in taskList"
          :key="key"
          :item="item"
          :params="params"
        />
      </van-list>
    </template>
    <div v-else class="empty-task-list">{{ emptyText }}</div>
    <!-- <div v-else class="empty-task-list">{{ $t("error.emptyTastList") }}</div> -->

    <van-button
      icon="plus"
      type="primary"
      round
      class="add-task"
      @click="addTask()"
      v-if="!isHideCreate"
    />

    <van-overlay
      :show="isLoading"
      @click="isLoading = false"
      class="van-overlay-loading"
    >
      <div class="wrapper-loading" @click.stop>
        <van-loading type="spinner" color="#1989fa" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { eventBus } from "@/main";
import UserData from "@/components/Home/User";
import TaskItem from "@/components/Task/Item";
import ApiDeals from "@/api/deals";
import ApiUsers from "@/api/users";
import { mapGetters } from "vuex";
import Button from "@/components/Global/Button.vue";

import moment from "moment";
import _ from "lodash";
export default {
  data() {
    return {
      showPopover: false,
      loading: false,
      isLoading: true,
      finished: false,
      isHideCreate: false,
      progress: {},
      filterBy: "active",
      taskList: [],
      newUser: {},
      actions: [
        { text: "Все", type: "all" },
        { text: "Активные", type: "active" },
        { text: "Созданные", type: "created" },
        { text: "Завершенные", type: "finished" },
      ],
      params: {
        limit: 10,
        offset: 0,
        today: moment().format("Y-MM-DD"),
        id_user: 0,
        type: "active",
      },
    };
  },
  computed: {
    ...mapGetters("User", ["selfUser"]),
    getDataOrFilter() {
      if (this.filterBy !== "all") {
        return _.find(this.actions, (item) => {
          return item.type == this.filterBy;
        }).text;
      }
      moment.locale("RU");

      return `Сегодня, ${moment().format("D MMMM")}`;
    },
    emptyText() {
      if (this.params.type == "all" || this.params.type == "active") {
        return "У вас нет дел";
      } else if (this.params.type == "finished") {
        return "У вас нет ни одного завершенного дела";
      }
      return "Список дел пуст";
    },
  },
  watch: {
    filterBy() {
      this.params.type = this.filterBy;
      this.getList();
    },
    "$route.query.user_id": {
      handler: function (user_id) {
        if (user_id) {
          this.isHideCreate = true;
        } else {
          this.isHideCreate = false;
        }
        this.getNewUser();
      },
      deep: true,
      immediate: true,
    },
    selfUser() {
      this.getList();
    },
  },
  components: { UserData, TaskItem, Button },
  methods: {
    goToReport() {
      this.$router.push("/reports");
    },
    onClickOutside() {
      this.showPopover = false;
    },
    onLoad() {
      this.params.offset += 10;
      ApiDeals.getDeals(this.params).then((responce) => {
        this.taskList.push(...responce.data);
        this.loading = false;
        if (responce.data.length < 1) {
          this.finished = true;
        }
      });
    },
    onOpen() {
      setTimeout(() => {
        document
          .querySelector(".popup-filter-content")
          .parentNode.parentNode.classList.add("popup-filter");
      });
    },
    addTask() {
      this.$router.push("/catalog");
      // eventBus.$emit("addTask");
    },
    getList() {
      this.isLoading = true;
      if (this.$route.query.user_id) {
        this.params.id_user = this.$route.query.user_id;
      } else {
        this.params.id_user = this.selfUser.id;
      }
      ApiDeals.getDeals(this.params)
        .then((responce) => {
          this.isLoading = false;
          this.taskList = responce.data;
          this.progress.completed_deals = responce.completed_deals;
          this.progress.all_deals = responce.all_deals;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getNewUser() {
      if (this.$route.query.user_id) {
        ApiUsers.getUser(this.$route.query.user_id).then((responce) => {
          this.newUser = responce.data;
        });
      } else {
        this.newUser = null;
      }
      this.getList();
    },
  },
  mounted() {
    moment.locale("RU");

    eventBus.$on("createdTask", () => {
      this.getList();
    });
  },
};
</script>

<style lang="scss" scoped>
.date-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  &__date {
    color: #0e1012;
    opacity: 0.4;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  &__btn {
    cursor: pointer;
    .van-popover__wrapper {
      position: relative;
    }
  }
}
.choosenFilter {
  background: #ff7a00;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 1px solid #fff;
  position: absolute;
  top: -2px;
  right: -1px;
}

.progress-bar {
  display: flex;
  align-items: center;
  gap: 6px;
  height: 8px;
  overflow: hidden;
  border-radius: 100px;
  margin-bottom: 20px;

  &__item {
    width: 100%;
    height: 8px;
    background: #e5e7e1;

    &.done {
      background: #81b38e;
    }
  }
}
.general-report-link {
  margin: 0 auto;
  margin-bottom: 20px;
  display: block;
  width: 100%;
}
</style>
