var render = function render(){var _vm=this,_c=_vm._self._c;return _c('van-skeleton',{attrs:{"title":"","avatar":"","row":3,"loading":_vm.isSelfUserLoading}},[_c('div',[_c('div',{class:[
				'user-data',
				{
					hasAnotherUser: _vm.newUser && Object.keys(_vm.newUser).length,
					hasGG: _vm.user.gg_profile && Object.keys(_vm.user.gg_profile).length
				}
			]},[(_vm.newUser && Object.keys(_vm.newUser).length)?_c('IconButton',{staticClass:"index-100",attrs:{"van-button":{ background: 'rgba(14, 16, 18, 0.16)' },"van-icon":{ name: 'arrow-left', size: 20, color: '#0E1012' }},on:{"click":function($event){return _vm.$router.go(-1)}}}):_vm._e(),_c('div',{staticClass:"user-data-info"},[_c('p',{class:[
						'user-data-info__name',
						{
							hasAnotherUser: _vm.newUser && Object.keys(_vm.newUser).length
						}
					]},[_vm._v(" "+_vm._s(_vm.fullname)+" ")]),(_vm.user.gg_profile)?_c('a',{staticClass:"user-data-info__gg",attrs:{"href":`https://grustnogram.ru/u/${_vm.user.gg_profile.nickname}`},on:{"click":function($event){return _vm.openGG()}}},[_c('img',{attrs:{"src":require("@/assets/images/GG.svg"),"alt":"","srcset":""}}),_vm._v(" @"+_vm._s(_vm.user.gg_profile.nickname)+" ")]):_vm._e()]),_c('div',{staticClass:"user-data-avatar"},[_c('router-link',{attrs:{"to":`/?user_id=${_vm.user.id || _vm.selfUser.id}`}},[_c('img',{staticClass:"user-data-avatar__avatar",attrs:{"src":_vm.user.avatar_250 || _vm.selfUser.avatar_250,"alt":_vm.user.id}})])],1)],1),(_vm.user.me === 0)?_c('Button',{staticStyle:{"margin-bottom":"25px"},attrs:{"button":{
				title: 'Отправить сообщение',
				color: '#0E1012',
				borderRadius: '100px',
				backgroundColor: 'transparent',
				height: '40px',
				fontSize: '16px',
				paddingX: '17px',
				borderColor: '#0E1012',
				width: '100%'
			}},nativeOn:{"click":function($event){return _vm.sendMessage()}}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }