<template>
	<van-skeleton title avatar :row="3" :loading="isSelfUserLoading">
		<div>
			<div
				:class="[
					'user-data',
					{
						hasAnotherUser: newUser && Object.keys(newUser).length,
						hasGG: user.gg_profile && Object.keys(user.gg_profile).length
					}
				]"
			>
				<IconButton class="index-100" :van-button="{ background: 'rgba(14, 16, 18, 0.16)' }" :van-icon="{ name: 'arrow-left', size: 20, color: '#0E1012' }" @click="$router.go(-1)" v-if="newUser && Object.keys(newUser).length" />
				<div class="user-data-info">
					<p
						:class="[
							'user-data-info__name',
							{
								hasAnotherUser: newUser && Object.keys(newUser).length
							}
						]"
					>
						{{ fullname }}
					</p>
					<a class="user-data-info__gg" :href="`https://grustnogram.ru/u/${user.gg_profile.nickname}`" v-if="user.gg_profile" @click="openGG()">
						<img src="@/assets/images/GG.svg" alt="" srcset="" />
						@{{ user.gg_profile.nickname }}
					</a>
				</div>
				<div class="user-data-avatar">
					<router-link :to="`/?user_id=${user.id || selfUser.id}`">
						<img class="user-data-avatar__avatar" :src="user.avatar_250 || selfUser.avatar_250" :alt="user.id" />
					</router-link>
				</div>
			</div>
			<Button
				v-if="user.me === 0"
				@click.native="sendMessage()"
				style="margin-bottom: 25px"
				:button="{
					title: 'Отправить сообщение',
					color: '#0E1012',
					borderRadius: '100px',
					backgroundColor: 'transparent',
					height: '40px',
					fontSize: '16px',
					paddingX: '17px',
					borderColor: '#0E1012',
					width: '100%'
				}"
			/>
		</div>
	</van-skeleton>
</template>

<script>
import IconButton from "@/components/Global/IconButton.vue";
import Button from "@/components/Global/Button.vue";
import { mapGetters } from "vuex";
import ApiUsers from "@/api/users";
export default {
	props: {
		newUser: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			user: {},
			isLoading: true
		};
	},
	watch: {
		newUser() {
			if (this.newUser) {
				this.user = JSON.parse(JSON.stringify(this.newUser));
			} else {
				this.user = JSON.parse(JSON.stringify(this.selfUser));
			}
		}
	},
	computed: {
		...mapGetters("User", ["selfUser", "isSelfUserLoading"]),
		fullname() {
			if (this.newUser) {
				return `${this.newUser.first_name} ${this.newUser.last_name}`;
			} else {
				return `${this.selfUser.first_name} ${this.selfUser.last_name}`;
			}
		}
	},
	components: {
		IconButton,
		Button
	},
	methods: {
		sendMessage() {
			const tg = window.Telegram.WebApp;

			ApiUsers.sendMessage(this.user.id).then(() => {
				tg.close();
			});
		},
		openGG() {
			window.location.href = `https://grustnogram.ru/u/${this.selfUser.gg_profile.nickname}`;
		}
	},
	mounted() {
		if (this.newUser) {
			this.user = JSON.parse(JSON.stringify(this.newUser));
		} else {
			this.user = JSON.parse(JSON.stringify(this.selfUser));
		}
	}
};
</script>

<style lang="scss" scoped>
.user-data {
	margin-bottom: 24px;
	display: flex;
	align-items: center;
	// justify-content: space-between;
	.user-data-avatar {
		margin-left: auto;
	}

	&.hasAnotherUser {
		align-items: center;
		.user-data-info {
			padding-left: 15px;
		}
		&.hasGG {
			align-items: flex-start;
		}
		.user-data-info__name {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
		}
	}
	&-info {
		color: #0e1012;
		max-width: 250px;
		width: 100%;
		&__name {
			font-family: "Prata";
			font-style: normal;
			font-weight: 400;
			font-size: 32px;
			line-height: 40px;
			margin-bottom: 12px;
			&.hasAnotherUser {
				margin-bottom: 0;
			}
		}
		&__gg {
			font-family: "Inter";
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			display: flex;
			align-items: center;
			color: #0e1012;

			text-decoration: none;
			img {
				margin-right: 8px;
			}
		}
	}
	&-avatar {
		display: flex;
		align-items: center;
		&__avatar {
			width: 56px;
			height: 56px;
			border-radius: 100%;
			margin-right: 19px;
		}
	}
}
</style>
