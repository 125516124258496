var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('user-data',{attrs:{"newUser":_vm.newUser}}),_c('div',{staticClass:"date-info"},[_c('div',{staticClass:"date-info__date"},[_vm._v(_vm._s(_vm.getDataOrFilter))]),_c('div',{staticClass:"date-info__btn"},[_c('van-popover',{attrs:{"trigger":"click","placement":"bottom-end","actions":_vm.actions,"close-on-click-outside":true},on:{"open":_vm.onOpen},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/images/candle.svg"),"alt":"","srcset":""}}),(_vm.filterBy && _vm.filterBy != 'all')?_c('div',{staticClass:"choosenFilter"}):_vm._e()]},proxy:true}]),model:{value:(_vm.showPopover),callback:function ($$v) {_vm.showPopover=$$v},expression:"showPopover"}},[_c('div',{staticClass:"popup-filter-content"},[_c('van-radio-group',{model:{value:(_vm.filterBy),callback:function ($$v) {_vm.filterBy=$$v},expression:"filterBy"}},_vm._l((_vm.actions),function(item,key){return _c('van-radio',{key:key,attrs:{"icon-size":"24px","name":item.type},on:{"click":function($event){_vm.showPopover = false}}},[_vm._v(_vm._s(item.text))])}),1)],1)])],1)]),(!_vm.newUser)?_c('div',{staticClass:"progress-bar"},_vm._l((this.progress.all_deals),function(item,key){return _c('div',{key:key,class:[
        'progress-bar__item',
        item <= _vm.progress.completed_deals ? 'done' : '',
      ]})}),0):_vm._e(),_c('Button',{staticClass:"general-report-link",attrs:{"button":{
      title: 'Общая лента отчетов',
      color: '#1D1B20',
      textAlign: 'center',
      borderRadius: '100px',
      backgroundColor: '#E5E7E1',
      height: '40px',
      fontSize: '14px',
      paddingX: '17px',
      borderColor: '#E5E7E1',
    }},nativeOn:{"click":function($event){return _vm.goToReport()}}}),(_vm.taskList.length)?[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"","loading-text":_vm.$t('loading')},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.taskList),function(item,key){return _c('TaskItem',{key:key,attrs:{"item":item,"params":_vm.params}})}),1)]:_c('div',{staticClass:"empty-task-list"},[_vm._v(_vm._s(_vm.emptyText))]),(!_vm.isHideCreate)?_c('van-button',{staticClass:"add-task",attrs:{"icon":"plus","type":"primary","round":""},on:{"click":function($event){return _vm.addTask()}}}):_vm._e(),_c('van-overlay',{staticClass:"van-overlay-loading",attrs:{"show":_vm.isLoading},on:{"click":function($event){_vm.isLoading = false}}},[_c('div',{staticClass:"wrapper-loading",on:{"click":function($event){$event.stopPropagation();}}},[_c('van-loading',{attrs:{"type":"spinner","color":"#1989fa"}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }